import { createActions, } from 'redux-actions';

export default createActions({
  MY_BETS: {
    CACHE: null,
    COMPUTE: null,
    PUSH: null,
    POP: null,
    CLEAR: null,
  },
});

import { BET_ON_TEEN_PATTI_BET_TYPES, ONE_DAY_TEEN_PATTI_BET_TYPES, } from '@ezugi/constants';

export const {
  SIX_CARD,
} = BET_ON_TEEN_PATTI_BET_TYPES; // TODO: remove BOTP unnecessary bets

export const {
  PLAYER_A,
  PLAYER_B,
  ONE_PAIR,
  STRAIGHT,
  STRAIGHT_FLUSH,
  FLUSH,
  THREE_OF_A_KIND,
} = ONE_DAY_TEEN_PATTI_BET_TYPES;

// TODO: remove PAIR_PLUS bet and any other BET_ON_TEEN_PATTI related bet types
export const PAIR_PLUS = 'PairPlus';

export const TOTALS = {
  // MAIN BETS
  TOTAL_PLAYER_A: `total${PLAYER_A}`,
  TOTAL_PLAYER_B: `total${PLAYER_B}`,
  // SIDE BETS
  TOTAL_ONE_PAIR: `total${ONE_PAIR}`,
  TOTAL_STRAIGHT: `total${STRAIGHT}`,
  TOTAL_STRAIGHT_FLUSH: `total${STRAIGHT_FLUSH}`,
  TOTAL_FLUSH: `total${FLUSH}`,
  TOTAL_THREE_OF_A_KIND: `total${THREE_OF_A_KIND}`,
};

export const SIDEBETS = {
  ONE_PAIR,
  THREE_OF_A_KIND,
  STRAIGHT,
  FLUSH,
  STRAIGHT_FLUSH,
};

export const MAIN_BETS = {
  PLAYER_A,
  PLAYER_B,
};

export const INITIAL_STATE = {
  // MAIN BETS
  [PLAYER_A]: 0,
  [PLAYER_B]: 0,
  // SIDE BETS
  [ONE_PAIR]: 0,
  [THREE_OF_A_KIND]: 0,
  [STRAIGHT]: 0,
  [FLUSH]: 0,
  [STRAIGHT_FLUSH]: 0,
};

export const BETS_ORDERS = {
  [PLAYER_A]: 0,
  [PLAYER_B]: 1,
  [ONE_PAIR]: 2,
  [THREE_OF_A_KIND]: 3,
  [STRAIGHT]: 4,
  [FLUSH]: 5,
  [STRAIGHT_FLUSH]: 6,
};


export default ONE_DAY_TEEN_PATTI_BET_TYPES;

import * as BET_TYPES from './betTypes';

const {
  MAIN_BETS: { PLAYER_A, PLAYER_B, },
  SIDEBETS: { ONE_PAIR, THREE_OF_A_KIND, STRAIGHT, FLUSH, STRAIGHT_FLUSH, },
} = BET_TYPES;

export const roundStepsKeys = {
  roundStep1: '1dtp_ui.round_step_1',
  roundStep2: '1dtp_ui.round_step_2',
  roundStep3: '1dtp_ui.round_step_3',
  roundStep4: '1dtp_ui.round_step_4',
  roundStep5: '1dtp_ui.round_step_5',
  roundStep6: '1dtp_ui.round_step_6',
};

export const betTypesKeys = {
  [PLAYER_A]: 'bets.player_a',
  [PLAYER_B]: 'bets.player_b',
  [ONE_PAIR]: 'poker_ui.onepair',
  [THREE_OF_A_KIND]: 'poker_ui.threeofkind',
  [STRAIGHT]: 'poker_ui.straight',
  [FLUSH]: 'poker_ui.flush',
  [STRAIGHT_FLUSH]: 'poker_ui.straightflush',
  playerA: 'bets.player_a',
  playerB: 'bets.player_b',
  RoyalFlush: 'poker_ui.royalflush',
  MiniRoyal: 'poker_ui.miniroyal',
  StraightFlush: 'poker_ui.straightflush',
  ThreeOfKind: 'poker_ui.threeofkind',
  FourOfKind: 'poker_ui.fourofkind',
  Straight: 'poker_ui.straight',
  FullHouse: 'poker_ui.fullhouse',
  Flush: 'poker_ui.flush',
  OnePair: 'poker_ui.onepair',
  TwoPairs: 'poker_ui.twopairs',
  HighCard: 'poker_ui.highcard',
};

import React from 'react';
import { applySpec, } from 'ramda';
import { useIntl, useGameProgress, useAppState, } from '@ezugi/hooks';

import { numberOfCardsSelector, } from '../../store/selectors/cards';
import { isHandheld, } from '../../utils';
import { Container, Label, } from './style';
import { roundStepsKeys, } from '../../constants/locales';


const mapStateToProps = applySpec({
  nbCards: numberOfCardsSelector,
});

const RoundStepInfo = () => {
  const progress = useGameProgress();
  const locales = useIntl({ keys: roundStepsKeys, });
  const [ { nbCards, }, ] = useAppState(
    mapStateToProps
  );
  return (
    !isHandheld && progress.areBetsOpen && (
      <Container>
        <Label>
          {locales[`roundStep${nbCards + 1}`]}
        </Label>
      </Container>
    )
  );
};

export default <RoundStepInfo />;

import { map, pluck, } from 'rxjs/operators';
import { ofType, } from 'redux-observable';
import { actions as bootstrapActions, selectors as bootstrapSelectors, } from '@ezugi/bootstrap';
import { formatPayouts, } from './utils';
import payoutsActions from '../../actions/payouts';

const {
  configActions: { config, },
} = bootstrapActions;
const {
  formatSelector,
} = bootstrapSelectors;

function payoutsEpic(action$, state$) {
  return action$.pipe(
    ofType(payoutsActions.payouts.set),
    pluck('payload'),
    map(({ payouts = {}, }) => {
      const { payoutFormat, } = formatSelector(state$.value);
      return config.update({ payouts: formatPayouts(payouts, payoutFormat), });
    }),
  );
}

export default payoutsEpic;

import styled, { css, keyframes, } from 'react-emotion';
import tinyColor from 'tinycolor2';
import { theme, } from '@ezugi/bootstrap';

import * as COLOURS from '../../constants/colors';

const SIDEBET_BG_COLOR = tinyColor(COLOURS.SIDEBET).setAlpha(0.65).toRgbString();
const SIDEBET_NO_PAYOUT_BG_COLOR = tinyColor(COLOURS.SIDEBET).setAlpha(0.8).toRgbString();
const { palette, } = theme;

export const glowingPulse = keyframes`
  from {
    background-color: ${SIDEBET_BG_COLOR};
  } to {
    background-color: ${COLOURS.SIDEBET};
  }
`;


export const Container = styled.div`
  position:relative;
  box-sizing: border-box;
  border: 1px ${COLOURS.SIDEBET} solid;
  background-color: ${SIDEBET_BG_COLOR};
  color: #FFFFFF;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  
  [data-columns=two] & {
    flex-flow: row;
    &:nth-of-type(odd):last-of-type {
      grid-column: 1 / span 2;
    }
  }
`;

export const totalSideBetChipStyle = {
  position: 'absolute',
  left: 0,
};

export const mobileTotalSideBetChipStyle = {
  top: 0,
};

export const betHoverStyles = css`
  cursor: pointer;
  &:hover {
    background-color: ${COLOURS.SIDEBET};
  }
`;

export const highForMobilStyle = css`
  [data-columns=two] & {
    height: 64px;
  }
`;

export const Label = styled.span`
  text-transform: uppercase;
  text-align: center;
  [data-columns=two] & {
    padding-right: .5em;
  }
`;

export const Payout = styled.span`
  text-transform: uppercase;
`;

export const isWinning = css`
  animation: ${glowingPulse} 0.17s infinite alternate;
`;

export const isGlowing = css`
  background-color: ${SIDEBET_BG_COLOR};
  animation: ${glowingPulse} 0.5s infinite alternate;
`;

export const resetAnimation = css`
  animation: none;
`;

export const noPayoutInPYB = css`
  background-color: ${SIDEBET_NO_PAYOUT_BG_COLOR};
  color: ${palette.primaryContrast};
`;

import { createActions, } from 'redux-actions';

export default createActions({
  TOTALS: {
    ADD: null,
    SET: null,
    RESET: null,
    CLEAR: null,
    CACHE: null,
  },
});

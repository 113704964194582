import { createSelector, } from 'reselect';
import {
  anyPass, has, keys, mergeRight, mergeWithKey, pick, prop, propOr,
} from 'ramda';
import { selectors as boostrapSelectors, } from '@ezugi/bootstrap';

import { MAIN_BETS, SIDEBETS, TOTALS, } from '../../constants/betTypes';

const { betsSelector, payoutsSelector, } = boostrapSelectors;

const { PLAYER_A, PLAYER_B, } = MAIN_BETS;
const { ONE_PAIR, STRAIGHT_FLUSH, THREE_OF_A_KIND, STRAIGHT, FLUSH, } = SIDEBETS;
const { TOTAL_PLAYER_A, TOTAL_PLAYER_B, TOTAL_ONE_PAIR, TOTAL_FLUSH, TOTAL_STRAIGHT, TOTAL_STRAIGHT_FLUSH,
  TOTAL_THREE_OF_A_KIND, } = TOTALS;

const defaultValue = {};

export const currentBetsSelector = createSelector(
  betsSelector,
  propOr(defaultValue, 'current')
);

export const betsHistorySelector = createSelector(
  betsSelector,
  prop('history')
);

export const lastBetsSelector = createSelector(
  betsSelector,
  propOr(defaultValue, 'last')
);

export const totalsSelector = createSelector(
  betsSelector,
  propOr(defaultValue, 'totals')
);

// CURRENT BETS
export const playerABetSelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_A)
);

export const playerBBetSelector = createSelector(
  currentBetsSelector,
  prop(PLAYER_B)
);

export const onePairBetSelector = createSelector(
  currentBetsSelector,
  prop(ONE_PAIR)
);

export const threeOfAKindBetSelector = createSelector(
  currentBetsSelector,
  prop(THREE_OF_A_KIND)
);

export const straightBetSelector = createSelector(
  currentBetsSelector,
  prop(STRAIGHT)
);

export const flushBetSelector = createSelector(
  currentBetsSelector,
  prop(FLUSH)
);

export const straightFlushBetSelector = createSelector(
  currentBetsSelector,
  prop(STRAIGHT_FLUSH)
);

// TOTALS
export const totalPlayerASelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_A)
);

export const totalPlayerBSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_PLAYER_B)
);

export const totalOnePairASelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_ONE_PAIR)
);
export const totalFlushSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_FLUSH)
);
export const totalStraightSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_STRAIGHT)
);
export const totalStraightFlushSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_STRAIGHT_FLUSH)
);
export const totalThreeOfAKindSelector = createSelector(
  totalsSelector,
  propOr(0, TOTAL_THREE_OF_A_KIND)
);

export const hasMainBetSelector = createSelector(
  currentBetsSelector,
  anyPass([ has(PLAYER_A), has(PLAYER_B), ])
);

export const hasSideBetSelector = createSelector(
  currentBetsSelector,
  anyPass([ has(ONE_PAIR), has(THREE_OF_A_KIND), has(STRAIGHT), has(FLUSH), has(STRAIGHT_FLUSH), ])
);

export const myBetsSelector = createSelector(
  betsSelector,
  propOr(defaultValue, 'myBets')
);

export const computeMyBetsSelector = createSelector(
  currentBetsSelector,
  payoutsSelector,
  (currentBets, currentPayouts) => {
    const filteredPayouts = pick(keys(currentBets), currentPayouts);
    const concatValues = (k, l, r) => mergeRight(l, r);
    return mergeWithKey(concatValues, filteredPayouts, currentBets);
  }

);

/* eslint-disable no-unused-vars */
// TODO: cleanup this file

import { MAIN_BETS, SIDEBETS, BETS_ORDERS, } from '../../../constants/betTypes';
/* import {
  FLUSH,
  FOUR_OF_KIND,
  FULL_HOUSE,
  PAIR,
  ROYAL_FLUSH,
  STRAIGHT,
  STRAIGHT_FLUSH,
  THREE_OF_KIND,
  TWO_PAIRS,
} from '../../../constants/strings'; */

/* export const payoutsIndexMap = {
  1: PAIR,
  2: TWO_PAIRS,
  3: THREE_OF_KIND,
  4: STRAIGHT,
  5: FLUSH,
  6: FULL_HOUSE,
  7: FOUR_OF_KIND,
  8: STRAIGHT_FLUSH,
  9: ROYAL_FLUSH,
  10: FULL_HOUSE,
}; */


/* function hasPayout(payouts, payoutOption) {
  return () => !!+payouts[payoutOption];
}

function getPayoutObj(p, payouts, payoutOption) {
  return {
    index: +p.IndexID,
    strength: +payouts[payoutOption],
    payout: `${payouts[payoutOption]}:1`,
    name: payoutsIndexMap[p.IndexID],
  };
} */

const PAYOUT_TYPE_MULTIPLIER = 'multiplier';
const PAYOUT_TYPE_FRACTIONAL = 'fraction';

export const ZERO_PAYOUT_VALUE = null;

const formatPayout = (value, type) => {
  if (!value) return ZERO_PAYOUT_VALUE;

  switch (type) {
  case PAYOUT_TYPE_MULTIPLIER:
    return `x${value + 1}`;
  case PAYOUT_TYPE_FRACTIONAL:
    return `${value}/1`;
  default:
    return `${value}:1`;
  }
};

export function formatPayouts(payoutsList = {}, format) {
  return {
    [MAIN_BETS.PLAYER_A]: {
      name: MAIN_BETS.PLAYER_A,
      index: BETS_ORDERS[MAIN_BETS.PLAYER_A],
      payout: formatPayout(payoutsList[MAIN_BETS.PLAYER_A], format),
    },
    [MAIN_BETS.PLAYER_B]: {
      name: MAIN_BETS.PLAYER_B,
      index: BETS_ORDERS[MAIN_BETS.PLAYER_B],
      payout: formatPayout(payoutsList[MAIN_BETS.PLAYER_B], format),
    },
    [SIDEBETS.ONE_PAIR]: {
      name: SIDEBETS.ONE_PAIR,
      index: BETS_ORDERS[SIDEBETS.ONE_PAIR],
      payout: formatPayout(payoutsList[SIDEBETS.ONE_PAIR], format),
    },
    [SIDEBETS.THREE_OF_A_KIND]: {
      name: SIDEBETS.THREE_OF_A_KIND,
      index: BETS_ORDERS[SIDEBETS.THREE_OF_A_KIND],
      payout: formatPayout(payoutsList[SIDEBETS.THREE_OF_A_KIND], format),
    },
    [SIDEBETS.STRAIGHT]: {
      name: SIDEBETS.STRAIGHT,
      index: BETS_ORDERS[SIDEBETS.STRAIGHT],
      payout: formatPayout(payoutsList[SIDEBETS.STRAIGHT], format),
    },
    [SIDEBETS.FLUSH]: {
      name: SIDEBETS.FLUSH,
      index: BETS_ORDERS[SIDEBETS.FLUSH],
      payout: formatPayout(payoutsList[SIDEBETS.FLUSH], format),
    },
    [SIDEBETS.STRAIGHT_FLUSH]: {
      name: SIDEBETS.STRAIGHT_FLUSH,
      index: BETS_ORDERS[SIDEBETS.STRAIGHT_FLUSH],
      payout: formatPayout(payoutsList[SIDEBETS.STRAIGHT_FLUSH], format),
    },
  };
}

import { handleActions, } from 'redux-actions';
import { dropLast, } from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { getTotalBet, } from '../../epics/bets/utils';
import totalsActions from '../../actions/totals';
import myBetsActions from '../../actions/myBets';

import * as BET_TYPES from '../../../constants/betTypes';

const {
  PLAYER_A,
  PLAYER_B,
  ONE_PAIR,
  STRAIGHT,
  STRAIGHT_FLUSH,
  FLUSH,
  THREE_OF_A_KIND,
} = BET_TYPES;


const { betActions, } = bootstrapActions;
const { totals, } = totalsActions;

export const INITIAL_STATE = {
  current: {},
  totalBet: 0,
};

export const INTIAL_STATE_MY_BETS = {
  myBets: {},
};

export const INITIAL_STATE_TOTALS = {
  totals: {
    // TOTALS FOR MAIN BETS
    [`total${PLAYER_A}`]: 0,
    [`total${PLAYER_B}`]: 0,
    // TOTALS FOR SIDE BETS
    [`total${ONE_PAIR}`]: 0,
    [`total${STRAIGHT}`]: 0,
    [`total${STRAIGHT_FLUSH}`]: 0,
    [`total${FLUSH}`]: 0,
    [`total${THREE_OF_A_KIND}`]: 0,
  },
};


INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    [betActions.bet.set]: ({ history, ...state }, { payload: { type, value, ...rest }, }) => {
      const current = {
        ...state.current,
        [type]: { value: ((state.current[type] || {}).value || 0) + value, ...rest, },
      };
      const t = getTotalBet(current);

      return {
        ...state,
        current,
        history: [ ...history, { ...state, current, totalBet: t, }, ],
      };
    },
    [betActions.bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      ...INITIAL_STATE_TOTALS,
      ...INTIAL_STATE_MY_BETS,
    }),
    [betActions.bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [betActions.bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [betActions.bet.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
      history: [ ...state.history, payload, ],
    }),
    [betActions.history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, }, ],
    }),
    [betActions.history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [betActions.totalBet.set]: (state, { payload: { value = INITIAL_STATE.totalBet, }, }) => ({
      ...state,
      totalBet: value,
    }),
    /* totals for each type of BET */
    [totals.set]: (state, { payload, }) => ({
      ...state,
      ...{
        totals: {
          ...payload,
        },
      },
    }),
    [totals.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE_TOTALS,
    }),
    [myBetsActions.myBets.push]: ({ myBets, ...state }, { payload, }) => ({
      ...state,
      myBets: { ...myBets, ...payload, },
    }),
    [myBetsActions.myBets.pop]: ({ myBets, ...state }) => ({
      ...state,
      myBets: dropLast(1, myBets),
    }),
    [myBetsActions.myBets.clear]: (state) => ({
      ...state,
      ...INTIAL_STATE_MY_BETS,
    }),
  },
  {
    ...INITIAL_STATE,
    ...INITIAL_STATE_TOTALS,
    ...INTIAL_STATE_MY_BETS,
  }
);
